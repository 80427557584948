import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "react-html5video/dist/styles.css";
import "./PreguntasPage.css";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import Accordion from "react-bootstrap/Accordion";

const faqs = [
  {
    title: "¿Como puedo combinar las dos pruebas?",
    text: (
      <p>La prueba se puede combinar con la de carretera con las siguientes categorías:
        <ul>
          <li>2 picos bike + 4 picos road masculino</li>
          <li>2 picos bike + 4 picos road femenino</li>
          <li>4 picos bike + 4 picos road masculino</li>
          <li>4 picos bike + 4 picos road femenino</li>
        </ul>
      </p>
    )
  },
  {
    title: "¿Cuántos kilómetros tiene la prueba?",
    text: <p>La prueba consta de dos distancias: 130km ó 66km</p>
  },
  {
    title: "¿Qué acumulado positivo tiene cada distancia?",
    text: (
      <p>
        La distancia larga 4 Picos (130km) tiene 2800 mts de acumulado y la
        distancia corta 2 Picos (66 km) tiene un acumulado de 1500 mts
      </p>
    )
  },
  {
    title: "¿Cuántos avituallamiento tiene la prueba?",
    text: (
      <p>
        La prueba consta de 6 avituallamientos; 5 avituallamientos sólido/líquidos, y un avituallamiento final de prueba.
      </p>
    )
  },
  {
    title: "¿Qué límite máximo de horas tengo para acabarla?",
    text: (
      <p>
        El tiempo máximo para realizar la prueba es de 11 horas y 15 minutos.
      </p>
    )
  },
  {
    title: "¿Hay tiempos de corte?",
    text: (
      <p>
        Si, los tiempos de corte son los siguientes:
        <ul>
          <li>Punto de corte 1. Km15: 11:00 horas</li>
          <li>Punto de corte 2. Km 60 (paso por meta): 14:30 horas</li>
          <li>Punto de corte 3. Km 90 (inicio subida 4 picos): 17:15 horas</li>
          <li>Punto de corte 4. Km 120 (meta): 20:00 horas.</li>
        </ul>
      </p>
    )
  },
  {
    title: "¿Está el recorrido flechado?",
    text: (
      <p>
        Si, el recorrido está marcado en su totalidad. El marcaje se realiza con
        flechas, cintas y señales que permitirán un correcto transcurso de la
        prueba
      </p>
    )
  },
  {
    title: "¿Pueden ayudarme durante el recorrido?",
    text: (
      <p>
        Si, cualquier persona te podrá ayudar, ya sea en temas mecánicos,
        recibiendo avituallamiento…
      </p>
    )
  },
  {
    title: "¿Qué pasa si no llego al punto de control?",
    text: (
      <p>
        Automáticamente quedará fuera de carrera, en este momento todo
        participante se considera en excursión personal y por lo tanto fuera de
        la cobertura ofrecida por la prueba, tanto en recorrido, como en cruces,
        avituallamientos u otro servicio ofrecido por la misma.
      </p>
    )
  },
  {
    title: "¿Si no puedo ir a la prueba, puedo ceder mi inscripción?",
    text: (
      <p>Si, antes de la fecha establecida en el reglamento de la prueba.</p>
    )
  },
  {
    title:
      "¿El seguro de cancelación me cubre cualquier tipo de circunstancia?",
    text: (
      <p>
        <strong>NO</strong>. El seguro cubre muchas situaciones, pero no todas,
        para tener más información revisa el apartado de “Cancelaciones” en el
        reglamento.
      </p>
    )
  },
  {
    title: "¿Cuándo debo recoger la ropa que he encargado?",
    text: (
      <p>
        Las prendas reservadas serán entregadas al participante <u><strong>el día del evento en la zona de “TIENDA”.</strong></u>
      </p>
    )
  },
  {
    title: "¿Me podrían enviar la ropa una vez pasada la fecha del evento?",
    text: (
      <p>
        <strong>NO</strong>. En caso de no recoger la ropa el día del evento, el
        participante se quedará sin dicha ropa.{" "}
        <strong>NO SE REALIZARÁN ENVÍOS</strong>
      </p>
    )
  },
  {
    title: "¿La prueba es abierta al tráfico?",
    text: (
      <p>
        SI, ASI ES. La prueba está abierta al tráfico por lo que se deberá
        respetar semáforos, cruces, modo de circulación, etc. La organización no
        se responsabiliza de los posibles percances acaecidos por no respetar la
        circulación y sus normas, pudiendo ser expulsados de la prueba en caso
        de que se vea a algún participante cometiendo una infracción.
      </p>
    )
  },
  {
    title: "¿Es obligatorio llevar móvil en la prueba?",
    text: (
      <p>
        Sí, para que la organización pueda ponerse en contacto si surge algún
        imprevisto o hay algún problema. Importante que tenga batería
        suficiente.
      </p>
    )
  },
  {
    title: "¿Qué hago si no decido seguir en la prueba?",
    text: (
      <p>
        Fundamental que de aviso a la organización lo antes posible para poder
        tener controlados a todos los participantes y velar por su seguridad.
      </p>
    )
  },
  {
    title: "¿Cuándo puedo retirar el dorsal?",
    text: (
      <p>
        La recogida de dorsales se realizará en{" "}
        <strong>EL PALACIO DE CONGRESOS DE PONTEVEDRA</strong> (u otro lugar que
        la organización decida y que será anunciado con antelación suficiente),
        el día{" "}
        <strong>
          9 de Mayo desde las 17:00 horas hasta las 21:00 horas y el sábado 10
          de mayo desde 7:00 horas hasta las 8:15 horas.
        </strong>
        <strong>Se recomienda</strong> a todos los participantes que realicen su
        recogida el viernes <strong>9 de Mayo de 2025.</strong>
      </p>
    )
  },
  {
    title: "¿Puede retirar el dorsal algún compañero por mí?",
    text: (
      <p>
        Sí, siempre que tenga DNI y los papeles que se deben tener cubiertos
        para la prueba.
      </p>
    )
  }
];

class PreguntasPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Embassador-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Embassador-title">Preguntas frecuentes</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <Accordion defaultActiveKey="0">
                  {faqs.map((faq, index) => {
                    const { title, text } = faq;
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{title}</Accordion.Header>
                        <Accordion.Body>{text}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <Footer />
                <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default PreguntasPage;
