import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "react-html5video/dist/styles.css";
import "./TracksPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import Image2 from "./image2.jpg";

class TracksPage extends React.Component {
  render() {
    return (
      <div className="Tracks">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Tracks-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Tracks-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Tracks-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Tracks-title">Tracks</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Tracks-info-article">
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-4-picos-bike-2021-82029996">
                      Track Pontevedra 4 Picos Bike 2021
                    </a>
                  </h4>
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-2-picos-bike-2021-82030277">
                      Track Pontevedra 2 Picos Bike 2021
                    </a>
                  </h4>
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-4-picos-bike-2022-oficial-101890938">
                      Track Pontevedra 4 Picos Bike 2022
                    </a>
                  </h4>
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-2-picos-bike-2022-oficial-101891419">
                      Track Pontevedra 2 Picos Bike 2022
                    </a>
                  </h4>
                    <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-4-picos-bike-2023-version-4-picos-133458665">
                      Track Pontevedra 4 Picos Bike 2023
                    </a>
                  </h4>
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-mountain-bike/pontevedra-4-picos-bike-2023-version-2-picos-133457644">
                      Track Pontevedra 2 Picos Bike 2023
                    </a>
                  </h4>                    
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-ciclismo/pontevedra-4-picos-bike-2024-version-4-picos-oficial-v5-169875944">
                      Track Pontevedra 4 Picos Bike 2024
                    </a>
                  </h4>
                  <h4>
                    <a href="https://es.wikiloc.com/rutas-ciclismo/pontevedra-4-picos-bike-2024-version-2-picos-oficial-v5-169876290">
                      Track Pontevedra 2 Picos Bike 2024
                    </a>
                  </h4>
                </article>
                
              </div>
              <div className="col-md-12 col-lg-6">
                <div
                  className="Tracks-info-img"
                  style={{
                    backgroundImage: `url(${Image2})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    height: "800px",
                    marginRight: "-15px",
                    marginLeft: "-15px"
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
                <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default TracksPage;
