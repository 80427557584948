import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import Image2 from "./image2.jpg";
import pdfEs from "./reglamento.pdf";
import "./RulesPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class RulesPage extends React.Component {
  render() {
    return (
      <div className="Rules">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Rules-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Rules-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Rules-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Rules-title">
                  <FormattedMessage
                    id="rules.title"
                    defaultMessage="Reglamento"
                  />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Rules-info-article">
                  <h4 className="Rules-info-title">
                    <FormattedMessage
                      id="rules.info.title"
                      defaultMessage="Puedes descargarte el reglamento completo en esta sección"
                    />
                  </h4>
                  <p className="Rules-info-text text-justify">
                    “PONTEVEDRA 4 PICOS BIKE - X EDICIÓN”, es una
                    prueba NO COMPETITIVA. Esta es una marcha deportiva de las
                    reguladas en el artículo 9 del Reglamento de
                    Cicloturismo-Ciclismo , dentro del marco de una especialidad
                    ciclista que concibe este deporte como un ejercicio físico
                    con fines de ocio y turístico o culturales, excluyendo la
                    competición. La prueba estará sometida a las disposiciones a
                    las disposiciones de los artículos 15 y a siguientes del
                    anexo II del Real Decreto 1428/2003, de 21 de noviembre, por
                    el que se aprueba el Reglamento General de Circulación para
                    la aplicación y desarrollo del texto articulado de la Ley
                    sobre tráfico, circulación de vehículos de motor y seguridad
                    vial, aprobado por el Real Decreto Legislativo 339/1990, de
                    2 marzo.
                  </p>
                  <div className="Rules-button-wrapper">
                    <a
                      className="Rules-pdf-button"
                      href={pdfEs}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reglamento
                    </a>
                    {/* <a className="Rules-pdf-button" href={pdfGl} target="_blank" rel="noopener noreferrer">Regulamento (GL)</a> */}
                  </div>
                </article>
              </div>
              <div className="col-md-12 col-lg-6">
                <div
                  className="Rules-info-img"
                  style={{
                    backgroundImage: `url(${Image2})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    height: "800px",
                    marginRight: "-15px",
                    marginLeft: "-15px"
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default RulesPage;
